import React from "react";
import { Container, Quote, Text } from "./quote.styled";

const QuoteParagraph = ({ content }) => (
  <Container>
    <Quote>“</Quote>
    <Text>{content}</Text>
  </Container>
);

export default QuoteParagraph;
