import styled from "styled-components";
import * as Colors from "@constants/colors";

const Container = styled.div`
  margin: 50px 0;
`;

const Caption = styled.p`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  width: 100%;
  padding: 24px 0 20px;
  border-bottom: 1px solid ${Colors.gray};
  margin: 0;
`;

export { Container, Caption };
