import React from "react";
import Image from "@components/image";
import { Container, Caption } from "./image.styled";

const ImageParagraph = ({ alt, image, imageCaption }) => (
  <Container>
    <Image image={image} width="100%" alt={alt} />
    {imageCaption ? <Caption>{imageCaption}</Caption> : ""}
  </Container>
);

export default ImageParagraph;
