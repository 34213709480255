import React from "react";
import paragraphTypes from "@constants/paragraph-types";
import TextParagraph from "./text";
import QuoteParagraph from "./quote";
import HeaderParagraph from "./header";
import ImageParagraph from "./image";

const renderParagraph = (
  { type, content, image, imageCaption, alt },
  index
) => {
  switch (type) {
    case paragraphTypes.quote:
      return <QuoteParagraph key={index} content={content} />;
    case paragraphTypes.image:
      return (
        <ImageParagraph
          key={index}
          alt={alt}
          image={image}
          imageCaption={imageCaption}
        />
      );
    case paragraphTypes.header:
      return <HeaderParagraph key={index} content={content} />;
    case paragraphTypes.text:
    default:
      return <TextParagraph key={index} content={content} />;
  }
};

const Post = ({ paragraphs }) => paragraphs.map(renderParagraph);

export default Post;
