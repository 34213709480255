import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "@components/layout";
import Post from "@modules/post";
import PostsList from "@modules/posts-list";
import { graphql, withPrefix } from "gatsby";

const BlogPost = ({ data, pageContext, location }) => {
  const { href, origin } = location;
  const post = pageContext.isFeatured
    ? data.featuredPost.nodes[0].frontmatter.featuredPost
    : data.post.nodes[0].frontmatter;
  const getInTouchBlockData = data.getInTouchBlockData.nodes[0].frontmatter;
  const initialPage = data.posts;
  const collectionId = initialPage.collection.id;
  const [latestPage, setLatestPage] = useState(initialPage);
  const [postsListItems, setPostsListItems] = useState(initialPage.nodes);

  const getPosts = () =>
    postsListItems.filter(({ slug }) => slug !== post.slug);

  const loadNextPage = useCallback(async () => {
    if (!latestPage.hasNextPage) return;

    const nextPageId = latestPage.nextPage.id;
    const path = withPrefix(
      `/paginated-${pageContext.type}-list/${collectionId}/${nextPageId}.json`
    );

    const res = await fetch(path);
    const json = await res.json();

    setPostsListItems(state => [...state, ...json.nodes]);
    setLatestPage(json);
  }, [latestPage, collectionId, pageContext.type]);

  return (
    <Layout>
      <Helmet>
        <title>{post.header}</title>
        <meta name="title" content={post.header} />
        <meta name="description" content={post.title} />
        {href && <meta name="url" content={href} />}

        <meta property="og:title" content={post.header} />
        <meta property="og:description" content={post.title} />
        {origin && post.image.publicURL && (
          <meta name="og:image" content={`${origin}${post.image.publicURL}`} />
        )}
        {href && <meta name="og:url" content={href} />}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.header} />
        <meta name="twitter:description" content={post.title} />
        {origin && post.image.publicURL && (
          <meta
            name="twitter:image"
            content={`${origin}${post.image.publicURL}`}
          />
        )}
        {href && <meta name="twitter:url" content={href} />}
      </Helmet>
      <Post
        data={post}
        getInTouchBlockData={getInTouchBlockData}
        location={location}
      />
      <PostsList
        header="More Blog Posts"
        maxItemsLength={3}
        linkTemplate={pageContext.linkTemplate}
        posts={getPosts()}
        loadNextPage={loadNextPage}
        hasNextPage={latestPage.hasNextPage}
      />
    </Layout>
  );
};

export const query = graphql`
  query(
    $slug: String!
    $filePath: String!
    $collectionName: String!
    $getInTouchBlockDataRegex: String!
  ) {
    featuredPost: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: $filePath }
        frontmatter: { featuredPost: { slug: { eq: $slug } } }
      }
    ) {
      nodes {
        frontmatter {
          featuredPost {
            slug
            header
            category
            date
            author
            title
            downloadLink
            downloadLinkLabel
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            paragraphs {
              type
              content
              imageAlt
              imageCaption
            }
          }
        }
      }
    }
    post: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: $filePath }
        frontmatter: { slug: { eq: $slug } }
      }
    ) {
      nodes {
        frontmatter {
          slug
          header
          category
          date
          author
          title
          downloadLink
          downloadLinkLabel
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          paragraphs {
            type
            content
            imageAlt
            imageCaption
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    posts: paginatedCollectionPage(
      collection: { name: { eq: $collectionName } }
      index: { eq: 0 }
    ) {
      nodes
      hasNextPage
      nextPage {
        id
      }
      collection {
        id
      }
    }
    getInTouchBlockData: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: $getInTouchBlockDataRegex } }
    ) {
      nodes {
        frontmatter {
          message
          containerBackground
          blockBackground
          buttonLabel
        }
      }
    }
  }
`;

export default BlogPost;
