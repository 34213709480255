import styled from "styled-components";

const Header = styled.h3`
  text-align: left;
  font-size: 22px;
  line-height: 28px;
  width: 100%;
  margin-bottom: 12px;
`;

export { Header };
