import styled from "styled-components";
import * as Fonts from "@constants/fonts";
import * as Colors from "@constants/colors";

const Container = styled.div`
  position: relative;
  border-left: 2px solid ${Colors.beachTeal};
  padding: 54px 45px 2px;
  margin: 50px 0 50px 0;
`;

const Quote = styled.h1`
  position: absolute;
  top: -80px;
  left: 45px;
  font-weight: bold;
  font-size: 110px;
  font-family: ${Fonts.ClearfaceFont};
`;

const Text = styled.p`
  text-align: left;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  width: 100%;
`;

export { Container, Quote, Text };
