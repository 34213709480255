import React from "react";
import * as Colors from "@constants/colors";

const FbIcon = ({ width = 20, height = 20, color = Colors.offWhite }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20.001"
  >
    <path
      id="Facebook"
      d="M10,20H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H18a2,2,0,0,1,2,2V18a2,2,0,0,1-2,2H13V12.47h2.563l.411-2.823H13V8.44c0-1.163.375-1.616,1.339-1.616H16v-2.7l-.032,0A14.638,14.638,0,0,0,13.925,4a3.921,3.921,0,0,0-2.876.989A4.276,4.276,0,0,0,10,8.131V9.647H7V12.47h3V20Z"
      transform="translate(0 0)"
      fill={color}
    />
  </svg>
);

export default FbIcon;
