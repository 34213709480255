import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import Image from "@components/image";
import FbIcon from "@svg-icons/fb-icon";
import LinkedinIcon from "@svg-icons/linkedin-icon";
import TwitterIcon from "@svg-icons/twitter-icon";
import * as Colors from "@constants/colors";
import PostParagraphs from "@components/paragraphs";
import { Wrapper, BlockSupTitle } from "@components/shared.styled";
import GetStarted from "@modules/get-started";
import {
  PostContainer,
  HeaderContainer,
  StyledBlockHeader,
  Info,
  ShareContainer,
  ShareGroup,
  Share,
  ShareLink,
  ContentWrapper,
  Title,
  ParagraphsWrapper,
  GrayLine,
  ActionsGroup,
  StyledButtonLink,
} from "./post.styled";

const Post = ({
  getInTouchBlockData: { containerBackground, blockBackground },
  data: {
    category,
    header,
    author,
    date,
    image,
    downloadLink,
    downloadLinkLabel,
    title,
    paragraphs,
  },
  location,
}) => {
  const formattedDate = new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <PostContainer>
      <HeaderContainer>
        <Wrapper>
          <BlockSupTitle>{category}</BlockSupTitle>
          <StyledBlockHeader color={Colors.white}>{header}</StyledBlockHeader>
          <Info>
            By {author} • {formattedDate}
          </Info>
        </Wrapper>
      </HeaderContainer>
      <Image image={image} width="auto" alt="Post Header Image" />
      <ShareContainer isDownloadLinkVisible={downloadLink}>
        <Wrapper>
          <ActionsGroup>
            {downloadLink ? (
              <StyledButtonLink
                to={downloadLink}
                text={downloadLinkLabel}
                isExternal
              />
            ) : (
              ""
            )}
            <ShareGroup isDownloadLinkVisible={downloadLink}>
              <Share>Share:</Share>
              <ShareLink>
                <FacebookShareButton url={location.href}>
                  <FbIcon />
                </FacebookShareButton>
              </ShareLink>
              <ShareLink>
                <LinkedinShareButton url={location.href}>
                  <LinkedinIcon />
                </LinkedinShareButton>
              </ShareLink>
              <ShareLink>
                <TwitterShareButton url={location.href}>
                  <TwitterIcon />
                </TwitterShareButton>
              </ShareLink>
            </ShareGroup>
          </ActionsGroup>
        </Wrapper>
      </ShareContainer>
      <ContentWrapper>
        <Wrapper>
          <Title>{title}</Title>
          <ParagraphsWrapper>
            <PostParagraphs paragraphs={paragraphs} />
          </ParagraphsWrapper>
        </Wrapper>
      </ContentWrapper>
      <GetStarted
        containerBackground={containerBackground}
        blockBackground={blockBackground}
      />
      <Wrapper>
        <GrayLine />
      </Wrapper>
    </PostContainer>
  );
};

export default Post;
