import styled from "styled-components";
import { Screens } from "@constants/screens";
import * as Fonts from "@constants/fonts";
import * as Colors from "@constants/colors";
import { BlockHeader } from "@components/shared.styled";
import ButtonLink from "@components/button-link";
import { Container, Block } from "@modules/get-started/get-started.styled";

const PostContainer = styled.div`
  ${Container} {
    padding: 125px 0 125px;

    @media (max-width: ${Screens.lg}) {
      padding-bottom: 50px;
    }
  }

  ${Block} {
    max-width: 740px;
  }
`;

const HeaderContainer = styled.section`
  background: ${Colors.charcoal};
  text-align: center;
  padding: 80px 0 32px;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0 10px;
  }
`;

const StyledBlockHeader = styled(BlockHeader)`
  margin-bottom: 10px;
`;

const Info = styled.p`
  color: ${Colors.grayLighter};
  width: 100%;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
  margin: 0;
`;

const ShareContainer = styled.div`
  background: ${Colors.charcoal};
  min-height: 60px;
  padding: 28px 0;
  ${({ isDownloadLinkVisible }) =>
    isDownloadLinkVisible
      ? `
    @media (max-width: ${Screens.md}) {
      padding-bottom: 60px;
    }
  `
      : ""};
`;

const ShareGroup = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  width: fit-content;
  margin-left: auto;
  position: absolute;
  right: 0;
  ${({ isDownloadLinkVisible }) =>
    isDownloadLinkVisible
      ? `
    bottom: -6px;
    @media (max-width: ${Screens.md}) {
      right: 50%;
      bottom: -45px;
      transform: translate(50%);
    }
  `
      : "bottom: -18px;"};
`;

const Share = styled.span`
  color: ${Colors.white};
  font-size: 20px;
  line-height: 36px;
  margin-right: 8px;
`;

const ShareLink = styled.div`
  margin-left: 16px;
`;

const ContentWrapper = styled.div`
  text-align: center;
  background: ${Colors.white};
  padding: 112px 0 112px;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const Title = styled.h2`
  font-family: ${Fonts.ClearfaceFont};
  font-size: 36px;
  line-height: 48px;
  text-align: left;
  margin: 0 auto 72px;
  max-width: 720px;

  @media (max-width: ${Screens.lg}) {
    font-size: 28px;
    line-height: 36px;
    margin: 60px 0 40px;
  }
`;

const ParagraphsWrapper = styled.div`
  max-width: 580px;
  margin: 0 auto;

  & :last-child {
    margin-bottom: 0;
  }
`;

const GrayLine = styled.div`
  height: 1px;
  background: ${Colors.grayDarker};
`;

const ActionsGroup = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const StyledButtonLink = styled(ButtonLink)`
  height: 64px;
  width: 274px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;

export {
  PostContainer,
  HeaderContainer,
  StyledBlockHeader,
  Info,
  ShareContainer,
  ShareGroup,
  Share,
  ShareLink,
  ContentWrapper,
  Title,
  ParagraphsWrapper,
  GrayLine,
  ActionsGroup,
  StyledButtonLink,
};
